import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connectHits, connectStateResults } from 'react-instantsearch-dom'
import Hits from './Hits'
import '@assets/css/components/search/connectors/infinite-hits.sass'

function trackProductImpressions({ hits }) {
  if (window) {
    const productSkus = hits.map(hit => hit.sku)
    if (window.dataLayer) {
      window.dataLayer.push({ event: 'ee_impression', ecommerce: { impressions: productSkus } })
    }
  }
}

function getCategory({ optionalFilters, categoryList }) {
  // if not empty, optionalFilters will include values such as "category:diningroom", so take its first value and remove the prefix
  const extCategory = optionalFilters.length ? optionalFilters[0].split(':')[1] : null

  // if category cannot be determined from optionalFilters, then get the category with the highest count in categoryList,
  // which will be an object such as { livingroom: 20, diningroom: 8, accessories: 80 }
  const highestCountCategory = Object.entries(categoryList)
    .map(c => {
      const [category, count] = c
      return { category, count }
    })
    .sort((a, b) => (a.count > b.count ? -1 : a.count < b.count ? 1 : 0)) // eslint-disable-line
    .map(i => i.category)
    .shift()

  return extCategory || highestCountCategory
}

function trackSearch({ categoryDetail, indexName, queryID }) {
  if (window && window.dataLayer) {
    let category
    try {
      category = getCategory(categoryDetail)
    } catch (e) {
      category = ''
    }

    window.dataLayer.push({
      event: 'algolia_query',
      algoliaIndexName: indexName, // the userToken of the current visitor to uniquely identify them
      algoliaQueryID: queryID,
      ...(category ? { category } : {}),
    })
  }
}

const ConnectedHits = React.memo(
  function ConnectedHits({ hits, searchResults }) {
    useEffect(() => {
      trackProductImpressions({ hits })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      if (!searchResults?.query) {
        return
      }
      const categoryDetail = {
        optionalFilters: searchResults?._rawResults[0]?.extensions?.queryCategorization?.optionalFilters ?? [],
        categoryList: searchResults?._rawResults[0]?.facets?.category ?? {},
      }

      trackSearch({ categoryDetail, indexName: searchResults.index, queryID: searchResults.queryID })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchResults?.query])

    return (
      <div
        id="productResultsWrapper"
        role="region"
        aria-label="Product Results"
        aria-describedby="productListCount"
        tabIndex="-1"
        className="search-wrapper"
      >
        <Hits hits={hits} />
      </div>
    )
  },
  ({ hits }, { hits: nextHits }) => hits === nextHits,
)
ConnectedHits.propTypes = {
  hits: PropTypes.any,
  searchResults: PropTypes.any,
}

ConnectedHits.displayName = 'ConnectedHits'

export default connectHits(connectStateResults(ConnectedHits))
