import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { connectStats, connectStateResults } from 'react-instantsearch-dom'
import { useTheme, Stack, styled, Grid, Link } from '@mui/material'
import { getRegionZone } from '@helpers/geo-location'

import { getTypeKey } from '@helpers/strapi'

// components
import PageRecentlyViewed from '@shared/recently-viewed'
import PageMarkdown from '@templates/strapi-cms/content-types/Markdown'
import PageBanner from '@templates/strapi-cms/content-types/Banner'
import PageSearchQuery from '@templates/strapi-cms/content-types/SearchQuery'
import PageButtonList from '@templates/strapi-cms/content-types/ButtonList'
import PageGrid from '@templates/strapi-cms/content-types/Grid'
import PageSlider from '@templates/strapi-cms/content-types/SliderWrapper'
import PageAccordion from '@templates/strapi-cms/content-types/Accordion'
import PageAccordionList from '@templates/strapi-cms/content-types/AccordionList'
import PageFinanceBannerGroup from '@templates/strapi-cms/content-types/FinanceBannerGroup'
import PageHtml from '@templates/strapi-cms/content-types/HTML'
import ContentGroup from '@templates/strapi-cms/content-types/ContentGroup'
import PageDeliveryWidget from '@templates/strapi-cms/content-types/DeliveryWidget'
import PageValidateCoupon from '@templates/strapi-cms/content-types/ValidateCoupon'
import PageImageList from '@templates/strapi-cms/content-types/ImageList'
import PageSpecial from '@templates/strapi-cms/content-types/Specials'
import PageVideo from '@templates/strapi-cms/content-types/Video'
import PageTabs from '@templates/strapi-cms/content-types/Tabs'
import PageTileGrid from '@templates/strapi-cms/content-types/TileGrid'
import PageSliderFilterList from '@templates/strapi-cms/content-types/SliderFilterList'
import PageImageGallery from '@templates/strapi-cms/content-types/ImageGallery/ImageGallery'
import PagePersonalizedModal from '@templates/strapi-cms/content-types/PersonalizedModal'
import BaseHeader from '@templates/strapi-cms/content-types/Header'
import PageBannerSwiper from '@templates/strapi-cms/content-types/BannerSwiper/BannerSwiper'
import PageAnchorNavigation from '@templates/strapi-cms/content-types/AnchorNavigation'
import PageBlog from '@templates/strapi-cms/content-types/Blog/index'
import { isEmpty } from 'lodash'
import SearchBox from '../search-box'

import { MUI_THEME } from '../../../@rtg2022'

const availableTypes = {
  // ? Breadcrumbs are rendered at the top of the Page by default, there is no need to render them here.
  PageBreadCrumbs: null,
  PageBanner,
  PageMarkdown,
  PageSearchQuery,
  PageButtonList,
  PageGrid,
  PageSlider,
  PageAccordion,
  PageAccordionList,
  PageRecentlyViewed,
  PageFinanceBannerGroup,
  PageHtml,
  PageDeliveryWidget,
  PageImageList,
  PageSpecial,
  PageVideo,
  PageTabs,
  PageTileGrid,
  PageSliderFilterList,
  PageImageGallery,
  BaseHeader,
  PageBannerSwiper,
  PagePersonalizedModal,
  PageAnchorNavigation,
  PageBlog,
}

const StyledNoResults = styled(Stack)(({ theme }) => ({
  backgroundColor: 'white',
  color: theme.palette.text.primary,
  marginTop: '1rem',
  position: 'relative',
  top: '-120px',
  zIndex: '10',
  [theme.breakpoints.up('md')]: {
    top: '-280px',
  },
  '.NoResultsGrid': {
    maxWidth: '656px',
    margin: '45px auto 0 auto',
    h3: {
      textAlign: 'center',
      fontWeight: 400,
      fontSize: '23px',
      lineHeight: '44px',
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      paddingBottom: '0.5rem',
      marginBottom: '35px',
      [theme.breakpoints.up('md')]: {
        fontSize: '33px',
      },
    },
    h4: {
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '26px',
      display: 'inline-block',
    },
    '.liveChatButton': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      fontSize: '14px',
      // This mimics link hover colors, but for the chat button
      textDecorationColor: 'rgba(0, 83, 160, 0.4)',
      [theme.breakpoints.up('md')]: {
        fontSize: '16px',
      },
      '&:hover': {
        textDecorationColor: 'inherit',
      },
    },
    p: {
      display: 'inline',
    },
    'p span': {
      color: theme.palette.secondary.dark,
      fontWeight: 600,
    },
  },
}))

const StyledSearchContainer = styled('div')(({ theme }) => ({
  backgroundColor: 'rgba(0, 83, 160, 0.04);',
  padding: '32px',
  marginTop: '45px',
  maxWidth: '1634px',
  width: '100%',
  h2: {
    fontWeight: 400,
    fontSize: '27px',
    lineHeight: '36px',
    textTransform: 'none',
    textAlign: 'center',
    b: {
      fontWeight: 600,
    },
    [theme.breakpoints.up('md')]: {
      lineHeight: '53px',
      fontSize: '40px',
      marginBottom: '1rem',
    },
  },

  section: {
    width: '778px',
    maxWidth: '100%',
    margin: '0 auto',
    input: {
      backgroundColor: 'white',
      color: theme.palette.secondary.dark,
    },
    '.ais-Highlight-highlighted': {
      color: theme.palette.secondary.lighter,
    },
    '.ais-Highlight-nonHighlighted': {
      color: `${theme.palette.secondary.dark} !important`,
    },
  },
}))

const StyledCenterWrapper = styled('div')(({ theme }) => ({
  display: 'block',
  maxWidth: '3003px',
  margin: '0 auto',
  [theme.breakpoints.up('md')]: {
    maxWidth: 'none',
    textAlign: 'left',
    display: 'inline-block',
  },
}))

const StyledDividerWrapper = styled(StyledCenterWrapper)(({ theme }) => ({
  textAlign: 'center',
  borderBottom: `1px solid rgba(0, 0, 0, 0.12)`,
  paddingBottom: '1rem',
  marginBottom: '1rem',
  display: 'inline-block',
  [theme.breakpoints.up('md')]: {
    paddingBottom: '0',
    marginBottom: '0',
    textAlign: 'left',
    borderBottom: 'none',
  },
}))

const StyledDividerGrid = styled(Grid)(({ theme }) => ({
  display: 'inline-block',
  [theme.breakpoints.up('md')]: {
    borderBottom: 'none',
    borderRight: `1px solid rgba(0, 0, 0, 0.12)`,
  },
}))

const BellowNoResultsContainer = styled('div')(({ theme }) => ({
  width: '86%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

const ContentContainer = styled(Grid)(() => ({
  width: '100%',
  display: 'block',
  maxWidth: '104rem',
  '& .MuiGrid-spacing-xs-2:not(.grid-wrapper), & .MuiGrid-spacing-xs-4:not(.grid-wrapper), & .MuiGrid-spacing-xs-6:not(.grid-wrapper)': {
    margin: 0,
  },
  '& > div:not(.page-content-group)': {
    '&:not(.grid-wrapper)': {
      width: '100%',
    },
  },
  '& > div.page-content-group > div': {
    '&:not(.grid-wrapper)': {
      width: '100%',
    },
  },
}))

const useNoResultsSearchQuery = () => {
  const data = useStaticQuery(graphql`
    query NoResultsSearch {
      strapiNoResultsSearch {
        Title
        DefaultContent {
          __typename
          ... on PageImageList {
            ImageList {
              ...StrapiImageListFragment
            }
          }
          ... on PageBanner {
            __typename
            Banner {
              ...StrapiPageBannerFragment
            }
          }
          ... on PageBreadCrumbs {
            __typename
            BreadCrumbs {
              ...StrapiPageBreadCrumbsFragment
            }
          }
          ... on PageMarkdown {
            Markdown {
              ...StrapiPageMarkdownFragment
            }
          }
          ... on PageSearchQuery {
            SearchQuery {
              ...SearchQueryStrapiFragment
            }
          }
          ... on PageButtonList {
            ButtonList {
              ...StrapiButtonListFragent
            }
          }
          ... on PageGrid {
            __typename
            Grid {
              ...StrapiPageGridFragment
            }
          }
          ... on PageSlider {
            Slider {
              ...StrapiSliderfragment
            }
          }
          ... on PageContentGroup {
            ContentGroup {
              ...StrapiContentGroupFragment
            }
          }
          ... on PagePersonalizedModal {
            PersonalizedModal {
              ...personalizedModalFragment
            }
          }
          ... on PageAccordion {
            Accordion {
              ...StrapiPageAccordionFragment
            }
          }
          ... on PageAccordionList {
            AccordionList {
              ...StrapiPageAccordionListFragment
            }
          }
          ... on PageFinanceBannerGroup {
            FinanceBannerGroup {
              ...StrapiFinanceBannerGroupFragment
            }
          }
          ... on PageRecentlyViewed {
            ShowRecentlyViewed
          }
          ... on PageHtml {
            HTML {
              ...StrapiHtmlFragment
            }
          }
          ... on PageDeliveryWidget {
            ...DeliveryWidgetFragment
          }
          ... on PageValidateCoupon {
            InvalidCouponRedirect {
              ...StrapiLinkFragment
            }
          }
          ... on PageSpecial {
            Specials {
              ...StrapiSpecialsFragment
            }
          }
          ... on PageVideo {
            Video {
              ...StrapiPageVideoFragment
            }
          }
          ... on PageTileGrid {
            TileGrid {
              ...StrapiTileGridFragment
            }
          }
          ... on PageSliderFilterList {
            SliderFilterList {
              ...SliderFilterListFragment
            }
          }
          ... on PageImageGallery {
            ImageGallery {
              ...ImageGalleryFragment
            }
          }
          ... on BaseHeader {
            ...StrapiHeaderFragment
          }
          ... on PageBannerSwiper {
            BannerSwiper {
              ...StrapiPageBannerSwiperFragment
            }
          }
          ... on PageTabs {
            TabGroup {
              ...StrapiPageTabGroupFragment
            }
          }
          ... on PageAnchorNavigation {
            AnchorNavigation {
              ...PageAnchorNavigationFragment
            }
          }
          ... on PageBlog {
            Blog {
              ...StrapiPageBlogFragment
            }
          }
        }
        Content_SE {
          __typename
          ... on PageImageList {
            ImageList {
              ...StrapiImageListFragment
            }
          }
          ... on PageBanner {
            __typename
            Banner {
              ...StrapiPageBannerFragment
            }
          }
          ... on PageBreadCrumbs {
            __typename
            BreadCrumbs {
              ...StrapiPageBreadCrumbsFragment
            }
          }
          ... on PageMarkdown {
            Markdown {
              ...StrapiPageMarkdownFragment
            }
          }
          ... on PageSearchQuery {
            SearchQuery {
              ...SearchQueryStrapiFragment
            }
          }
          ... on PageButtonList {
            ButtonList {
              ...StrapiButtonListFragent
            }
          }
          ... on PageGrid {
            __typename
            Grid {
              ...StrapiPageGridFragment
            }
          }
          ... on PageSlider {
            Slider {
              ...StrapiSliderfragment
            }
          }
          ... on PageContentGroup {
            ContentGroup {
              ...StrapiContentGroupFragment
            }
          }
          ... on PagePersonalizedModal {
            PersonalizedModal {
              ...personalizedModalFragment
            }
          }
          ... on PageAccordion {
            Accordion {
              ...StrapiPageAccordionFragment
            }
          }
          ... on PageAccordionList {
            AccordionList {
              ...StrapiPageAccordionListFragment
            }
          }
          ... on PageFinanceBannerGroup {
            FinanceBannerGroup {
              ...StrapiFinanceBannerGroupFragment
            }
          }
          ... on PageRecentlyViewed {
            ShowRecentlyViewed
          }
          ... on PageHtml {
            HTML {
              ...StrapiHtmlFragment
            }
          }
          ... on PageDeliveryWidget {
            ...DeliveryWidgetFragment
          }
          ... on PageValidateCoupon {
            InvalidCouponRedirect {
              ...StrapiLinkFragment
            }
          }
          ... on PageSpecial {
            Specials {
              ...StrapiSpecialsFragment
            }
          }
          ... on PageVideo {
            Video {
              ...StrapiPageVideoFragment
            }
          }
          ... on PageTileGrid {
            TileGrid {
              ...StrapiTileGridFragment
            }
          }
          ... on PageSliderFilterList {
            SliderFilterList {
              ...SliderFilterListFragment
            }
          }
          ... on PageImageGallery {
            ImageGallery {
              ...ImageGalleryFragment
            }
          }
          ... on BaseHeader {
            ...StrapiHeaderFragment
          }
          ... on PageBannerSwiper {
            BannerSwiper {
              ...StrapiPageBannerSwiperFragment
            }
          }
          ... on PageTabs {
            TabGroup {
              ...StrapiPageTabGroupFragment
            }
          }
          ... on PageAnchorNavigation {
            AnchorNavigation {
              ...PageAnchorNavigationFragment
            }
          }
          ... on PageBlog {
            Blog {
              ...StrapiPageBlogFragment
            }
          }
        }
        Content_FL {
          __typename
          ... on PageImageList {
            ImageList {
              ...StrapiImageListFragment
            }
          }
          ... on PageBanner {
            __typename
            Banner {
              ...StrapiPageBannerFragment
            }
          }
          ... on PageBreadCrumbs {
            __typename
            BreadCrumbs {
              ...StrapiPageBreadCrumbsFragment
            }
          }
          ... on PageMarkdown {
            Markdown {
              ...StrapiPageMarkdownFragment
            }
          }
          ... on PageSearchQuery {
            SearchQuery {
              ...SearchQueryStrapiFragment
            }
          }
          ... on PageButtonList {
            ButtonList {
              ...StrapiButtonListFragent
            }
          }
          ... on PageGrid {
            __typename
            Grid {
              ...StrapiPageGridFragment
            }
          }
          ... on PageSlider {
            Slider {
              ...StrapiSliderfragment
            }
          }
          ... on PageContentGroup {
            ContentGroup {
              ...StrapiContentGroupFragment
            }
          }
          ... on PagePersonalizedModal {
            PersonalizedModal {
              ...personalizedModalFragment
            }
          }
          ... on PageAccordion {
            Accordion {
              ...StrapiPageAccordionFragment
            }
          }
          ... on PageAccordionList {
            AccordionList {
              ...StrapiPageAccordionListFragment
            }
          }
          ... on PageFinanceBannerGroup {
            FinanceBannerGroup {
              ...StrapiFinanceBannerGroupFragment
            }
          }
          ... on PageRecentlyViewed {
            ShowRecentlyViewed
          }
          ... on PageHtml {
            HTML {
              ...StrapiHtmlFragment
            }
          }
          ... on PageDeliveryWidget {
            ...DeliveryWidgetFragment
          }
          ... on PageValidateCoupon {
            InvalidCouponRedirect {
              ...StrapiLinkFragment
            }
          }
          ... on PageSpecial {
            Specials {
              ...StrapiSpecialsFragment
            }
          }
          ... on PageVideo {
            Video {
              ...StrapiPageVideoFragment
            }
          }
          ... on PageTileGrid {
            TileGrid {
              ...StrapiTileGridFragment
            }
          }
          ... on PageSliderFilterList {
            SliderFilterList {
              ...SliderFilterListFragment
            }
          }
          ... on PageImageGallery {
            ImageGallery {
              ...ImageGalleryFragment
            }
          }
          ... on BaseHeader {
            ...StrapiHeaderFragment
          }
          ... on PageBannerSwiper {
            BannerSwiper {
              ...StrapiPageBannerSwiperFragment
            }
          }
          ... on PageTabs {
            TabGroup {
              ...StrapiPageTabGroupFragment
            }
          }
          ... on PageAnchorNavigation {
            AnchorNavigation {
              ...PageAnchorNavigationFragment
            }
          }
          ... on PageBlog {
            Blog {
              ...StrapiPageBlogFragment
            }
          }
        }
        Content_TX {
          __typename
          ... on PageImageList {
            ImageList {
              ...StrapiImageListFragment
            }
          }
          ... on PageBanner {
            __typename
            Banner {
              ...StrapiPageBannerFragment
            }
          }
          ... on PageBreadCrumbs {
            __typename
            BreadCrumbs {
              ...StrapiPageBreadCrumbsFragment
            }
          }
          ... on PageMarkdown {
            Markdown {
              ...StrapiPageMarkdownFragment
            }
          }
          ... on PageSearchQuery {
            SearchQuery {
              ...SearchQueryStrapiFragment
            }
          }
          ... on PageButtonList {
            ButtonList {
              ...StrapiButtonListFragent
            }
          }
          ... on PageGrid {
            __typename
            Grid {
              ...StrapiPageGridFragment
            }
          }
          ... on PageSlider {
            Slider {
              ...StrapiSliderfragment
            }
          }
          ... on PageContentGroup {
            ContentGroup {
              ...StrapiContentGroupFragment
            }
          }
          ... on PagePersonalizedModal {
            PersonalizedModal {
              ...personalizedModalFragment
            }
          }
          ... on PageAccordion {
            Accordion {
              ...StrapiPageAccordionFragment
            }
          }
          ... on PageAccordionList {
            AccordionList {
              ...StrapiPageAccordionListFragment
            }
          }
          ... on PageFinanceBannerGroup {
            FinanceBannerGroup {
              ...StrapiFinanceBannerGroupFragment
            }
          }
          ... on PageRecentlyViewed {
            ShowRecentlyViewed
          }
          ... on PageHtml {
            HTML {
              ...StrapiHtmlFragment
            }
          }
          ... on PageDeliveryWidget {
            ...DeliveryWidgetFragment
          }
          ... on PageValidateCoupon {
            InvalidCouponRedirect {
              ...StrapiLinkFragment
            }
          }
          ... on PageSpecial {
            Specials {
              ...StrapiSpecialsFragment
            }
          }
          ... on PageVideo {
            Video {
              ...StrapiPageVideoFragment
            }
          }
          ... on PageTileGrid {
            TileGrid {
              ...StrapiTileGridFragment
            }
          }
          ... on PageSliderFilterList {
            SliderFilterList {
              ...SliderFilterListFragment
            }
          }
          ... on PageImageGallery {
            ImageGallery {
              ...ImageGalleryFragment
            }
          }
          ... on BaseHeader {
            ...StrapiHeaderFragment
          }
          ... on PageBannerSwiper {
            BannerSwiper {
              ...StrapiPageBannerSwiperFragment
            }
          }
          ... on PageTabs {
            TabGroup {
              ...StrapiPageTabGroupFragment
            }
          }
          ... on PageAnchorNavigation {
            AnchorNavigation {
              ...PageAnchorNavigationFragment
            }
          }
          ... on PageBlog {
            Blog {
              ...StrapiPageBlogFragment
            }
          }
        }
        Content_OOM {
          __typename
          ... on PageImageList {
            ImageList {
              ...StrapiImageListFragment
            }
          }
          ... on PageBanner {
            __typename
            Banner {
              ...StrapiPageBannerFragment
            }
          }
          ... on PageBreadCrumbs {
            __typename
            BreadCrumbs {
              ...StrapiPageBreadCrumbsFragment
            }
          }
          ... on PageMarkdown {
            Markdown {
              ...StrapiPageMarkdownFragment
            }
          }
          ... on PageSearchQuery {
            SearchQuery {
              ...SearchQueryStrapiFragment
            }
          }
          ... on PageButtonList {
            ButtonList {
              ...StrapiButtonListFragent
            }
          }
          ... on PageGrid {
            __typename
            Grid {
              ...StrapiPageGridFragment
            }
          }
          ... on PageSlider {
            Slider {
              ...StrapiSliderfragment
            }
          }
          ... on PageContentGroup {
            ContentGroup {
              ...StrapiContentGroupFragment
            }
          }
          ... on PagePersonalizedModal {
            PersonalizedModal {
              ...personalizedModalFragment
            }
          }
          ... on PageAccordion {
            Accordion {
              ...StrapiPageAccordionFragment
            }
          }
          ... on PageAccordionList {
            AccordionList {
              ...StrapiPageAccordionListFragment
            }
          }
          ... on PageFinanceBannerGroup {
            FinanceBannerGroup {
              ...StrapiFinanceBannerGroupFragment
            }
          }
          ... on PageRecentlyViewed {
            ShowRecentlyViewed
          }
          ... on PageHtml {
            HTML {
              ...StrapiHtmlFragment
            }
          }
          ... on PageDeliveryWidget {
            ...DeliveryWidgetFragment
          }
          ... on PageValidateCoupon {
            InvalidCouponRedirect {
              ...StrapiLinkFragment
            }
          }
          ... on PageSpecial {
            Specials {
              ...StrapiSpecialsFragment
            }
          }
          ... on PageVideo {
            Video {
              ...StrapiPageVideoFragment
            }
          }
          ... on PageTileGrid {
            TileGrid {
              ...StrapiTileGridFragment
            }
          }
          ... on PageSliderFilterList {
            SliderFilterList {
              ...SliderFilterListFragment
            }
          }
          ... on PageImageGallery {
            ImageGallery {
              ...ImageGalleryFragment
            }
          }
          ... on BaseHeader {
            ...StrapiHeaderFragment
          }
          ... on PageBannerSwiper {
            BannerSwiper {
              ...StrapiPageBannerSwiperFragment
            }
          }
          ... on PageTabs {
            TabGroup {
              ...StrapiPageTabGroupFragment
            }
          }
          ... on PageAnchorNavigation {
            AnchorNavigation {
              ...PageAnchorNavigationFragment
            }
          }
          ... on PageBlog {
            Blog {
              ...StrapiPageBlogFragment
            }
          }
        }
      }
    }
  `)
  return data || null
}

const NoResultsSearch = ({ query }) => {
  useTheme(MUI_THEME)
  const { strapiNoResultsSearch: StrapiData } = useNoResultsSearchQuery()
  const data = StrapiData
  const emptyNoResultsContent = isEmpty(data?.Title)
  const region = getRegionZone()?.region
  const regionBasedContent = data?.[`Content_${region}`] || data?.DefaultContent
  const onChatRequest = () => {
    // eslint-disable-next-line no-undef
    if (insideFrontInterface && insideFrontInterface.chatReady) {
      // eslint-disable-next-line no-undef
      insideFrontInterface.openChatPane()
    } else {
      console.warn('Chat not ready')
    }
  }

  return (
    <StyledNoResults alignItems="center" spacing={1}>
      <StyledSearchContainer>
        <h2>
          No results found for: <b>"{query}"</b>
        </h2>
        <SearchBox mobileHitsCount viewName="search-no-results" setUrlQuery={false} />
      </StyledSearchContainer>
      <Grid container className="NoResultsGrid">
        <Grid item xs={12}>
          <h3>Need help? Chat with an expert</h3>
        </Grid>
        <StyledDividerGrid item xs={12} md={6} textAlign="center">
          <StyledDividerWrapper>
            <h4>Get personalized support</h4>
            <br />
            <Link href="/customer-service">Visit our Contact page</Link>
          </StyledDividerWrapper>
        </StyledDividerGrid>
        <Grid item xs={12} md={6} textAlign="center">
          <StyledCenterWrapper>
            <p>
              Call <Link href="tel:1-888-709-5380">1-888-709-5380</Link>
            </p>
            <br />
            <p>
              Text <span>ROOMS (76667)</span> Keyword SALES
            </p>
            <br />
            <button type="button" className="liveChatButton" onClick={onChatRequest}>
              Live Chat
            </button>
          </StyledCenterWrapper>
        </Grid>
      </Grid>
      <BellowNoResultsContainer>
        {regionBasedContent &&
          !emptyNoResultsContent &&
          Array.isArray(regionBasedContent) &&
          regionBasedContent.length > 0 && (
            <ContentContainer container>
              {regionBasedContent.map((contentData, index) => {
                if (!contentData?.__typename) {
                  return null
                }

                if (contentData.__typename === 'PageValidateCoupon' && contentData.InvalidCouponRedirect) {
                  return <PageValidateCoupon key={getTypeKey(contentData)} link={contentData.InvalidCouponRedirect} />
                }

                if (contentData.__typename === 'PageContentGroup') {
                  return <ContentGroup data={contentData?.ContentGroup} key={getTypeKey(contentData)} index={index} />
                }
                const Component = availableTypes[contentData.__typename]

                return Component ? (
                  <Component contentIndex={index} data={contentData} key={getTypeKey(contentData)} />
                ) : null
              })}
            </ContentContainer>
          )}
      </BellowNoResultsContainer>
    </StyledNoResults>
  )
}

NoResultsSearch.propTypes = {
  query: PropTypes.string.isRequired,
}

const ConnectedNoResultsSearch = connectStats(
  connectStateResults(props => {
    const {
      nbHits,
      searching,
      searchState: { query },
    } = props
    if (nbHits > 0 && !searching) {
      return null
    }
    return <NoResultsSearch query={query} />
  }),
)

export default ConnectedNoResultsSearch
export { NoResultsSearch }
