import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { setupAnalytics } from '@helpers/google-tag-manager'
import { getURLParam } from '@helpers/general'
import { checkPLALocation } from '@helpers/static-pdp'
import { getRegionZone } from '@helpers/geo-location'
import { useDispatch } from 'react-redux'
import Banner from '@templates/strapi-cms/content-types/Banner'
import Layout from '../components/layout'
import SearchResults from '../components/searchv2/search-results'

import InstantSearchRouter from '../components/shared/instant-search-router'
import QueryRuleCustomData from '../components/shared/query-rule-custom-data'
import ProductQuickView from '../components/product/product-quick-view'
import '../assets/css/pages/search.sass'

const displayFacets = ['brand', 'color_family', 'category', 'sub_category', 'material_family', 'style']

const useFinanceBannerQuery = () => {
  const data = useStaticQuery(graphql`
    query SearchPageFinanceBanner {
      allStrapiFinanceBannerGroup(filter: { Title: { eq: "Production Finance Banner Group" } }) {
        edges {
          node {
            id
            Title
            SE {
              Banner {
                ...StrapiPageBannerFragment
              }
            }
            FL {
              Banner {
                ...StrapiPageBannerFragment
              }
            }
            TX {
              Banner {
                ...StrapiPageBannerFragment
              }
            }
            PR {
              Banner {
                ...StrapiPageBannerFragment
              }
            }
          }
        }
      }
    }
  `)
  return data || null
}

const SearchPage = ({ pageContext, ...restProps }) => {
  const dispatch = useDispatch()
  const [pagePath, setPagePath] = useState('/search')
  const [searchState, setSearchState] = useState({})
  const { region } = getRegionZone()
  const { allStrapiFinanceBannerGroup } = useFinanceBannerQuery()
  const financeBanner = allStrapiFinanceBannerGroup?.edges?.[0]?.node[region] || {}

  const campaignSku = getURLParam('campaign_sku')

  useEffect(() => {
    const path = pageContext?.matchPath ? pageContext.matchPath.replace(/\*/g, '') : '/search'
    setupAnalytics({ pageData: { type: 'search', title: 'Search', path } })
    setPagePath(path)
  }, [pageContext])

  useEffect(() => {
    if (campaignSku) checkPLALocation(dispatch)
  }, [campaignSku, dispatch])

  return (
    <Layout pageContext={pageContext} {...restProps}>
      <Helmet meta={[{ name: 'robots', content: 'noindex, nofollow' }]} />
      {campaignSku && <ProductQuickView sku={campaignSku} componentPage="search-pla-link" />}
      <InstantSearchRouter setGlobalSearchState={setSearchState} pageContext={pageContext} {...restProps}>
        <QueryRuleCustomData />
        <SearchResults
          searchState={searchState}
          displayFilters={displayFacets}
          matchPath={pagePath}
          gridWidth={2}
          source="search"
        />
      </InstantSearchRouter>
      {financeBanner && <Banner data={financeBanner} />}
    </Layout>
  )
}
SearchPage.propTypes = {
  pageContext: PropTypes.shape({
    matchPath: PropTypes.string,
  }),
}

export default SearchPage
