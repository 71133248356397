import React, { useRef, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { objectOf, any, number } from 'prop-types'
import { styled } from '@mui/material'
import { connectHitInsights } from 'react-instantsearch-dom'
import insightsClient from 'search-insights'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import ProductTile from '../../product/product-tile'
import { getQueryPlpKey } from './ConnectedHitsLoadMore'
import '@assets/css/components/search/connectors/infinite-hits.sass'

insightsClient('init', {
  appId: process.env.GATSBY_ALGOLIA_APP_ID,
  apiKey: process.env.GATSBY_ALGOLIA_API_KEY,
})

const Hit = React.memo(
  ({ hit, insights, index }) => {
    const [isShopCollectionEnabled, setShopCollectionEnabled] = useState(false)
    const queryClient = useQueryClient()
    const hitRef = useRef(null)
    const isShopCollectionFeatureEnabled = useFeatureIsOn('shop-collection')

    useEffect(() => {
      if (isShopCollectionFeatureEnabled) {
        setShopCollectionEnabled(true)
      } else {
        setShopCollectionEnabled(false)
      }
    }, [isShopCollectionFeatureEnabled])

    const handleCaptureClick = () => {
      insights('clickedObjectIDsAfterSearch', {
        eventName: 'Product Clicked',
      })

      if (hitRef) {
        const { offsetTop } = hitRef.current
        // WE need to subtract navigation height (140)
        const moveTo = offsetTop - 140

        queryClient.prefetchQuery({
          queryKey: getQueryPlpKey('scrollTo'),
          queryFn: () => moveTo,
        })
      }
    }

    const ProductTileWrapper = styled('div')`
      height: 100%;
    `

    return (
      <ProductTileWrapper ref={hitRef} key={hit.sku || hit.tileIndex} onClickCapture={handleCaptureClick}>
        <ProductTile
          data={hit}
          index={index}
          viewType="grid"
          source="search"
          last={index === hit.__position}
          shopCollection={isShopCollectionEnabled}
          featureVariant="plp"
        />
      </ProductTileWrapper>
    )
  },
  ({ hit, index }, { hit: nextHit, index: nextIndex }) =>
    index === nextIndex || JSON.stringify(hit) === JSON.stringify(nextHit),
)

Hit.displayName = 'Hit'
Hit.propTypes = {
  hit: objectOf(any),
  insights: any,
  index: number.isRequired,
}

export default connectHitInsights(insightsClient)(Hit)
