import React from 'react'
import { connectStats, connectStateResults, connectSortBy } from 'react-instantsearch-dom'
import '@assets/css/components/search/connectors/stats.sass'
import { Stack } from '@mui/material'
import Stats from '@components/plp/plp-parts/Stats'
import { getRegionZone } from '@helpers/geo-location'

const StatsWrapper = connectStats(
  connectStateResults(({ nbHits, searching, refineFn }) => {
    if (nbHits === 0 && !searching) {
      return null
    }
    const handleClearSortBy = () => refineFn(`search-${getRegionZone().region}-${process.env.GATSBY_ENV_SHORT}`)
    return <Stats nbHits={nbHits} searching={searching} cbFunction={handleClearSortBy} />
  }),
)

const SortByWrapper = connectSortBy(({ refine }) => (
  <Stack direction="row" alignItems="center" spacing={1}>
    <StatsWrapper refineFn={refine} />
  </Stack>
))

const ConnectStats = () => <SortByWrapper items={[]} />

export default ConnectStats
