import PropTypes from 'prop-types'
import React, { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import Helmet from 'react-helmet'
import { Grid, Paper } from '@material-ui/core'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material'
import { breakPoints, colors } from '@constants/styles'
import CircleCounter from '@components/shared/circle-counter'
import { useTest } from '@hooks/useTest'
import SearchFilters from './search-filters'
import SearchSorting from './search-sorting'
import ConnectedHits from './connectors/ConnectedHits'
import ConnectedStats from './connectors/ConnectedStats'
import ConnectedNoResultsSearch from './connectors/ConnectedNoResultsSearch'

import ConnectedPagination from './connectors/ConnectedPagination'
import { SortFilter } from '../plp/search-results'
import { CustomConnectedCurrentRefinements } from './connectors/CustomCurrentRefinements'

const SearchContainerWrapper = styled('div')`
  background-color: ${colors.white};
`

const LeftBarWrapper = styled('div')`
  display: none;
  background-color: ${colors.white};
  height: fit-content;
  min-width: 280px;
  padding: 16px;
  border-left: 1px solid #dedede;

  @media only screen and (min-width: ${breakPoints['large-max']}) {
    display: block;
  }
`

const Summary = styled(Grid)`
  padding: 10px;
  background-color: rgba(0, 83, 160, 0.04);
  margin-bottom: 20px;
  @media only screen and (max-width: ${breakPoints['large-max']}) {
    display: none;
  }
`

const SummaryTop = styled(Grid)`
  margin: 0 -15px 0 -15px;
  width: calc(100% + 30px);
  padding: 0 15px;
  border-bottom: 1px solid #dedede;
  border-top: 1px solid #dedede;
  position: sticky;
  top: 58px;
  z-index: 10;
  background-color: #fff;
  justify-content: space-between;
  @media (min-width: 768px) {
    top: 130px;
  }
  @media only screen and (min-width: ${breakPoints['large-max']}) {
    display: none;
  }
`

const SearchMobileTitle = styled('span')`
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  color: #333;
  margin-top: 12px;
  margin-left: 10px;
`

const Wrapper = styled('div')`
  @media only screen and (min-width: ${breakPoints['large-min']}) {
    display: flex;
  }
`
const SidebarMobileWrapper = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  min-width: 280px;
  min-height: 100%;
  background-color: #f6f6f6;
  padding: 16px 10px;
`

const MobileHeaderWrapper = styled(Grid)`
  margin-bottom: 24px;
`

const CloseButton = styled('button')`
  display: grid;
  place-content: center;
  align-self: flex-start;
  color: #333;
`

const SidebarMobileModalWrapper = styled('div')`
  display: ${({ openModalSidebar }) => (openModalSidebar ? 'block' : 'none')};
  position: fixed;
  z-index: 4000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
`

const SearchResultsWrapper = styled(Paper)`
  flex-grow: 1;
`

const SearchResults = props => {
  const { displayFilters, matchPath, searchState } = props
  const isMobile = useSelector(state => state.global.isMobile)
  const [openModalSidebar, setOpenModalSidebar] = useState(false)
  const { generateTestId } = useTest()

  const countFilters = useCallback(
    () =>
      Object.values(searchState.refinementList || {}).reduce((acc, next) => {
        if (next instanceof Array) {
          return acc + next.length
        }

        return next ? acc + 1 : acc
      }, 0),
    [searchState],
  )

  const filtersQuantity = countFilters()

  const closeModal = useCallback(e => {
    const isOutsideModal = Array.from(e.target.classList)
      .join(' ')
      .includes('SidebarMobileModalWrapper')

    if (isOutsideModal) {
      setOpenModalSidebar(false)
    }
  }, [])

  return (
    <SearchContainerWrapper className="search-results">
      {matchPath.includes('/search') && (
        <>
          <Helmet title="Search Results - Rooms To Go" />
          <h1 className="home-heading">Search Results</h1>
        </>
      )}
      <Wrapper>
        {/* Filters + Stats */}
        <SummaryTop container id="header-filters-search" justifyContent="space-between" alignItems="center">
          <ConnectedStats absolutePosition={false} />
          <SortFilter
            onClick={() => setOpenModalSidebar(true)}
            data-testid={generateTestId('search-results', 'sort-filter-btn')}
          >
            <span>Sort &amp; Filter</span>
            {filtersQuantity > 0 && <CircleCounter quantity={filtersQuantity} />}
          </SortFilter>
        </SummaryTop>

        {isMobile && (
          <SidebarMobileModalWrapper openModalSidebar={openModalSidebar} onClick={closeModal}>
            <SidebarMobileWrapper>
              <MobileHeaderWrapper container justifyContent="space-between" alignItems="flex-end">
                <SearchMobileTitle>Sort &amp; Filter</SearchMobileTitle>
                <CloseButton
                  type="button"
                  title="Clear the search query."
                  hidden=""
                  onClick={() => setOpenModalSidebar(false)}
                  tabIndex={0}
                >
                  <CloseIcon />
                </CloseButton>
              </MobileHeaderWrapper>
              <SearchSorting />
              <SearchFilters attributes={displayFilters} matchPath={matchPath} />
            </SidebarMobileWrapper>
          </SidebarMobileModalWrapper>
        )}

        {/* DESKTOP */}
        <LeftBarWrapper>
          <Summary container justifyContent="space-between" alignItems="center">
            <ConnectedStats absolutePosition={false} />
          </Summary>
          <SearchSorting />
          <SearchFilters attributes={displayFilters} matchPath={matchPath} />
        </LeftBarWrapper>

        <SearchResultsWrapper container>
          <CustomConnectedCurrentRefinements searchState={searchState} />
          <ConnectedHits />
          <div className="pagination">
            <ConnectedPagination padding={2} />
          </div>
        </SearchResultsWrapper>
      </Wrapper>
      <ConnectedNoResultsSearch />
    </SearchContainerWrapper>
  )
}

SearchResults.propTypes = {
  displayFilters: PropTypes.any,
  matchPath: PropTypes.string,
  searchState: PropTypes.object,
}

export default SearchResults
