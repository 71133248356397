import React from 'react'
import styled, { keyframes } from 'styled-components'
import PropTypes from 'prop-types'
import Hit from './Hit'
import '@assets/css/components/search/connectors/infinite-hits.sass'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const HitsWrapper = styled.div`
  animation: ${fadeIn} 500ms ease-out forwards;
`

const tileOuterSpacing = '1.25rem'
const tileWidth = {
  SMALL: '100%',
  MEDIUM: '33.33333%',
  LARGE: '25%',
}

const TileWrapper = styled.div`
  width: calc(${tileWidth.SMALL}) !important;
  margin-left: 0 !important;
  margin-right: 0 !important;

  @media print, screen and (min-width: 750px) {
    width: calc(${tileWidth.MEDIUM} - ${tileOuterSpacing}) !important;
    margin: calc(${tileOuterSpacing} / 2) !important;
  }
`

const Hits = React.memo(({ hits }) => (
  <div
    id="productResultsWrapper"
    role="region"
    aria-label="Product Results"
    aria-describedby="productListCount"
    tabIndex="-1"
    className="search-wrapper"
  >
    <div className="ais-InfiniteHits">
      <div className="ais-InfiniteHits-list grid-x">
        {hits.map((tile, index) => (
          <TileWrapper key={tile.sku || tile.tileIndex} className="ais-InfiniteHits-item cell">
            <Hit key={tile.sku} hit={tile} index={index} />
          </TileWrapper>
        ))}
      </div>
    </div>
  </div>
))
Hits.propTypes = {
  hits: PropTypes.any,
}

Hits.displayName = 'Hits'

export default Hits
