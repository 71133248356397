/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types'
import React from 'react'
import { Box, Button, LinearProgress, Typography } from '@material-ui/core'
import '@assets/css/components/product/product-pagination.sass'
import styled from 'styled-components'

const LinearProgressCustom = styled(LinearProgress)`
  background-color: hsl(240deg 0% 90%);
  border-radius: 2px;
  .MuiLinearProgress-bar {
    background-color: hsl(240deg 0% 60%);
  }
`
const LoadMoreNav = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 300px;
  align-items: center;
`

export default function LoadMorePagination({ onLoadMore, currentItems, totalItems }) {
  return (
    <Box style={{ display: 'flex', justifyContent: 'center' }}>
      <LoadMoreNav style={{ paddingTop: 44 }}>
        <Box style={{ display: 'flex', flexDirection: 'column', gap: '5px', textAlign: 'center', width: '100%' }}>
          <Typography style={{ fontSize: '0.75rem', fontWeight: '600', color: '#333' }}>
            Showing {currentItems} of {totalItems} Products
          </Typography>
          <LinearProgressCustom variant="determinate" value={(currentItems * 100) / totalItems} />
        </Box>
        <Button
          style={{ textTransform: 'unset', width: '200px' }}
          onClick={onLoadMore}
          variant="outlined"
          color="primary"
        >
          Load More
        </Button>
      </LoadMoreNav>
    </Box>
  )
}

LoadMorePagination.propTypes = {
  onLoadMore: PropTypes.any,
  currentItems: PropTypes.any,
  totalItems: PropTypes.any,
}
