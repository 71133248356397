import React, { useEffect, useState } from 'react'
import { getRegionZone } from '@helpers/geo-location'
import ConnectedSortBy from './connectors/ConnectedSortBy'

const SearchSorting = () => {
  const [searchIndex, setSearchIndex] = useState(null)
  const [searchZone, setSearchZone] = useState(null)

  function initialSearchIndex() {
    return `search-${getRegionZone().region}-${process.env.GATSBY_ENV_SHORT}`
  }

  function initialSearchZone() {
    const { zone } = getRegionZone()
    return zone === 0 ? '' : `-zone${zone}`
  }

  useEffect(() => {
    setSearchIndex(initialSearchIndex())
    setSearchZone(initialSearchZone())
  }, [])

  if (!searchIndex) return null
  return (
    <ConnectedSortBy
      uniqueNameForId="aisSort"
      defaultRefinement={searchIndex}
      items={[
        {
          value: searchIndex,
          label: 'Our Recommendations',
        },
        {
          value: `${searchIndex}${searchZone}-price-asc`,
          label: 'Lowest Price',
        },
        {
          value: `${searchIndex}${searchZone}-price-desc`,
          label: 'Highest Price',
        },
        {
          value: `${searchIndex}-availability`,
          label: 'Availability',
        },
        {
          value: `${searchIndex}-ratings`,
          label: 'Highest Rated',
        },
      ]}
    />
  )
}

export default SearchSorting
